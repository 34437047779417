//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below

//! import a custom font below
@font-face {
  font-display: fallback;
  font-family: 'Libre-Baskerville-Reg';
  font-style: normal;
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
    url('fonts/libre-baskerville-v5-latin-regular.woff2') format('woff2'),
    url('fonts/libre-baskerville-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'OrangeJuice';
  font-style: normal;
  src: local('OrangeJuice'), url('fonts/orange-juice/orange-juice.ttf') format('TrueType');
}

@font-face {
  font-family: 'GotHeroin';
  font-style: normal;
  src: local('GotHeroin'), url('fonts/GotHeroin/Got_Heroin.ttf') format('TrueType');
}

//! set font variables
$font-heading: 'Libre-Baskerville-Reg', Fallback, 'Georgia', serif;
$font-body: 'GotHeroin';

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  color: $black;
  font-family: $font-body;
  font-size: 1.6rem;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 1.7rem;
  font-weight: 400;
}
