//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #444;
$black: #09090e;

$blue: #afd3f1;
$pink: #fccddc;
$yellow: #e8e86a;
//? override for project clarity

$color-primary: $yellow;
$color-secondary: $pink;
$color-tertiary: $blue;
