*::selection {
  background: $pink;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  min-height: 100%;
  width: 100%;
}

.wrapper {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: $color-primary;
  text-decoration: none;
  transition: transform 0.25s ease-in;

  &:hover {
    transform: scale(1.05) rotate(4deg);
  }
}

img {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
}

#loading {
  align-items: center;
  background: #868686b5;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 0.5s;
  width: 100%;

  .loading-spinner {
    animation: spin 3s infinite linear;
    height: 140px;
    width: auto;
  }

  .loading-message {
    bottom: 20px;
    height: auto;
    position: absolute;
    right: 20px;
    // width: 250px;
  }
}

#output {
  height: calc(480px / 2);
  left: 15px;
  position: absolute;
  top: 15px;
  width: calc(640px / 2);
}

#about {
  position: absolute;
  right: 25px;
  top: 25px;

  img {
    height: auto;
    width: 200px;
  }
}

#usercontrols {
  align-items: flex-end;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  font-family: 'OrangeJuice';
  font-size: 8rem;
  position: absolute;
  right: 25px;

  a {
    color: $yellow;
    display: flex;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  img {
    height: auto;
    max-width: 170px;
  }
}

.not-active {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
  text-decoration: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(10deg) scale(7.8);
  }

  90% {
    transform: rotate(-20deg) scale(3.8);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(1.2deg);
  }

  90% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
